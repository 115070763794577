import * as AuthenticationStore from './Account/AuthenticationStore';
import * as PublicStore from './Common/PublicStore';
import * as NotificationStore from './Common/NotificationStore';
import * as LoaderStore from './Common/LoaderStore';
import * as SignerStore from './Signing/SignerStore';
import * as DownloadStore from './Download/DownloadStore';
import * as UTEStore from './UTE/UTEStore';

// The top-level state object
export interface ApplicationState {
    userData: AuthenticationStore.ITokenData;
    signerData: SignerStore.ISignerData;
    publicData: PublicStore.IPublicData;
    publicUserToken: PublicStore.IPublicUserTokenData;
	notification: NotificationStore.NotificationState;
    loaderData: LoaderStore.ILoadingData;
    downloadData: DownloadStore.IDownloadData;
    uteData: UTEStore.IUTEData;
}

export const reducers = {
    userData: AuthenticationStore.tokenReducer,
    publicData: PublicStore.publicReducer,
    publicUserToken: PublicStore.tokenReducer,
    signerData: SignerStore.signerReducer,
    notification: NotificationStore.reducer,
    loaderData: LoaderStore.tokenReducer,
	downloadData: DownloadStore.reducer,
    uteData: UTEStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}