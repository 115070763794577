import * as React from 'react';
import { Header } from './Header';
import { ClientHeaderInfo } from '../../Core/Domain/ViewModels/User';
import Notification from '../Common/Notification/NotificationContainer';
import { Toaster } from '../../components/Common/Notification/SiriusNotifier';
import { SessionTimeout } from '../Account/SessionTimeout';
import * as PublicStore from '../../store/Common/PublicStore';
import * as OtpStore from '../../store/Account/OtpStore';
import * as UTEStore from '../../store/UTE/UTEStore';
import { RouteComponentProps } from 'react-router';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { MobileViewNotification } from '../Common/Notification/MobileViewNotification';
import { handleResponse } from '../../store/Library';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { validateError } from '../Helper/Validations';
import { FooterComponent } from 'cp-common-ui-components';
import { SIGNER_BASE_URL } from '../../utils/AppConstants';
import { injectPendoScript } from '../Common/CustomHooks/Pendo';

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);
const logger = new LoggerFactory().getTelemetryLogger();

export type LayoutProps = {
    userData: OtpStore.ITokenData;
    publicUserToken: PublicStore.IPublicUserTokenData;
    publicData: PublicStore.IPublicData;
    uteData: UTEStore.IUTEData;
} & typeof PublicStore.actionCreators &
    typeof OtpStore.actionCreators &
    typeof UTEStore.actionCreators &
    RouteComponentProps<{}>;

interface LayoutStates {
    contactPerson: ClientHeaderInfo;
}

declare global {
    interface Window {
        Variables: any;
        pendo: any;
    }
}

export class EsignPageLayout extends React.Component<LayoutProps, LayoutStates, {}> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            contactPerson: {
                companyName: '',
                contactPerson: 0,
                emailAddress: '',
                extension: '',
                firstName: '',
                lastName: '',
                phone: '',
            },
        };
        this.OnMobileProceedClick = this.OnMobileProceedClick.bind(this);
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        let clientGuid = '';
        if (param.Id) {
            clientGuid = param.Id;
            this.props.requestPrimaryDetails(param.Id, () => {
                injectPendoScript(this.props);
                this.props.requestClientUTEInfo(clientGuid);
            });
            this.props.requestCompanyLogo(param.Id);
        } else {
            clientGuid = param.clientId;
            this.props.requestPrimaryDetails(param.clientId, () => {
                this.props.requestClientUTEInfo(clientGuid);
            });
            this.props.requestCompanyLogo(param.clientId);
        }
        //WalkMe envId number 0 = Prod and 3 = Test (QA | DEV | Staging)
        if (!(window as any)._walkMe || !(window as any)._walkMe.getEnvId) {
            this.addWalkMeScript(clientGuid);
        } else if (
            (window as any)._walkMe &&
            (window as any)._walkMe.getEnvId() != 3 &&
            (window as any)._walkMe.getEnvId() != 0
        ) {
            this.addWalkMeScript(clientGuid);
        }
    }

    private OnMobileProceedClick = () => {
        sessionStorage.setItem('proceed_mobile_view', 'true');
        window.location.assign(this.props.location.pathname);
    };
    private getProceedValue = () => {
        return sessionStorage.getItem('proceed_mobile_view') == 'true';
    };

    addWalkMeScript = (clientGuid: any) => {
        fetch(SIGNER_BASE_URL + `api/Common/GetWalkMeScript/` + clientGuid, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(function (response: string) {
                if (response && response.length > 0) {
                    let scriptElement = document.createElement('script');
                    scriptElement.type = 'text/javascript';
                    scriptElement.async = true;
                    scriptElement.text = response;
                    logger.trackTrace(`GetWalkmeScript response ${response}`);
                    document.head.appendChild(scriptElement);
                    (window as any).loadWalkMe && (window as any).loadWalkMe();
                }
            })
            .catch(function (error: any) {
                console.log(error);
                logger.trackError(`Error occurred at GetWalkMeScript ${validateError(error)}`);
            });
    };

    public render() {
        return (
            <div>
                <Header
                    title={
                        this.props.publicData.primaryDetails.companyData &&
                        this.props.publicData.primaryDetails.companyData.CompanyName
                    }
                    loggedIn={localStore.get('loggedIn') ? true : false}
                    history={this.props.history}
                    match={this.props.match}
                    isMobileView={this.props.publicData.primaryDetails.isMobileView ? !this.getProceedValue() : false}
                    companyLogoPath={
                        this.props.publicData.companyLogo && this.props.publicData.companyLogo.isCompanyLogo
                            ? this.props.publicData.companyLogo.companyLogoPath
                            : null
                    }
                    contactPerson={this.props.publicData.primaryDetails.contactPerson}
                    clientUTEInfo={this.props.uteData.clientUTEInfo}
                />
                {this.props.publicData.primaryDetails &&
                (this.props.publicData.primaryDetails.isMobileView ? this.getProceedValue() : true) ? (
                    <div id="content-wrapper" className="contentWrapper">
                        <div>
                            <Notification />
                            {!localStore.get('loggedIn')
                                ? this.props.children
                                : this.props.userData.token || this.props.publicUserToken.token
                                ? this.props.children
                                : null}
                        </div>
                        {localStore.get('loggedIn') ? (
                            <SessionTimeout
                                history={this.props.history}
                                match={this.props.match}
                                refreshToken={this.props.refreshToken}
                                dispatchUserToken={this.props.dispatchUserToken}
                            />
                        ) : (
                            <br />
                        )}
                    </div>
                ) : (
                    <MobileViewNotification
                        primaryDetails={this.props.publicData.primaryDetails}
                        OnMobileProceedClick={this.OnMobileProceedClick}
                    />
                )}
                <FooterComponent />
                <Toaster />
            </div>
        );
    }
}
