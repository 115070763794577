import { IClientUTEInfo } from "src/Core/Domain/ViewModels/PrimaryDetails";
import { actionTypes } from "../ActionTypes";
import { HttpAction } from "../Common/LoaderStore";
import { NotificationAction, StatusType } from "../Common/NotificationStore";
import { AppThunkAction } from "..";
import axios, { AxiosResponse } from "axios";
import { axiosFetch } from "src/Core/Services/DataAccess/DataService.Axios";

export interface IUTEData{
    clientUTEInfo: IClientUTEInfo;
}

export interface ReceiveClientUTEInfoAction {
    type: actionTypes.RECEIVE_CLIENT_UTE_INFO;
    clientUTEInfo: IClientUTEInfo;
}

type KnownAction = DispatchAction | HttpAction | NotificationAction;

type DispatchAction = ReceiveClientUTEInfoAction
export const actionCreators = {
   requestClientUTEInfo: (Id: string, callback?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return axiosFetch(Id).get<IClientUTEInfo>('api/OneHub/GetClientUTEInfo/' + Id)
            .then((response: AxiosResponse<IClientUTEInfo>) => {
                const { data } = response;
                dispatch({
                    type: actionTypes.RECEIVE_CLIENT_UTE_INFO, clientUTEInfo: data
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
            });
   }
}


const unloadedState: IUTEData = {
    clientUTEInfo: {}
} as IUTEData;

export const reducer = (state: IUTEData = unloadedState, incomingAction: KnownAction): IUTEData => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_CLIENT_UTE_INFO:
            return {
                clientUTEInfo: action.clientUTEInfo
            } as IUTEData;
        default:
            return state || unloadedState;
    }
}


