import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IDocumentInfo } from '../../Core/Domain/ViewModels/DocumentInfo';
import { DisplayDownloadFile } from '../../Core/Utilities/DisplayDownloadFile';
import { AxiosResponse } from 'axios';
import { axiosFetch } from '../../Core/Services/DataAccess/DataService.Axios';
import  history  from '../../Core/Services/DataAccess/History';
import { HttpAction } from '../Common/LoaderStore';
import { DownloadStep } from '../../Core/Common/Enums';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

export interface IDownloadData {
    documents: IDocumentInfo[],
}

export interface RequestDocumentsAction {
    type: actionTypes.REQUEST_DOCUMENTS;
    clientGuid: string;
}

export interface ReceiveDocumentsAction {
    type: actionTypes.RECEIVE_DOCUMENTS;
    documents: IDocumentInfo[],
}

type KnownAction =
    DispatchAction |
    HttpAction |
    NotificationAction;

type DispatchAction = RequestDocumentsAction | ReceiveDocumentsAction


export const actionCreators = {
    requestDocuments: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        axiosFetch(clientGuid).get<IDocumentInfo[]>('api/Signing/GetAllDocumentsAsync')
            .then(function (response: AxiosResponse<IDocumentInfo[]>) {
                const { data } = response;
                dispatch({
                    type: actionTypes.RECEIVE_DOCUMENTS, documents: data
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                //if (error.response.status == "403" || error.response.status == "500") {
                //    history.push(
                //        '/invalid',
                //        {
                //            statusCode: error.response.status,
                //            statusText: error.response.statusText,
                //            message: error.response.data.error
                //        }
                //    );
                //}
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`requestDocuments failed for the request having parameters ${clientGuid} with error ${error.message}`)
            });
        dispatch({ type: actionTypes.REQUEST_DOCUMENTS, clientGuid: clientGuid });
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
    },
    downloadDocument: (clientGuid: string, documentGuid: string, fileName: string, isSigningCompleted: boolean, storageAccountId: number):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            responseType: 'blob'
        };
        axiosFetch(clientGuid).getWithConfig<Blob>('api/Download/DownloadSignedDocumentAsync?'
            + 'documentGuid=' + documentGuid + "&fileName=" + encodeURIComponent(fileName) + "&isSigningCompleted=" + isSigningCompleted + "&storageAccountId=" + storageAccountId, options)
            .then(function (response: AxiosResponse<Blob>) {
                const { data } = response;
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                logger.trackError(`downloadDocument failed for the request having parameters ${JSON.stringify({ clientGuid: clientGuid, documentGuid: documentGuid, fileName: fileName})} with error ${error.message}`)
            });
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
    },
    downloadAuditFile: (clientGuid: string, fileName: string, storageAccountId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            responseType: 'blob'
        };
        axiosFetch(clientGuid).getWithConfig<Blob>('api/Download/DownloadAuditFileAsync?'
            + "fileName=" + fileName + "&storageAccountId=" + storageAccountId, options)
            .then(function (response: AxiosResponse<Blob>) {
                const { data } = response;
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`downloadAuditFile failed for the request having parameters ${JSON.stringify({ clientGuid: clientGuid, fileName:fileName})} with error ${error.message}`)
            });
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
    },
    downloadAllDocument: (clientGuid: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep, isMigratedSigningIdForAudit: boolean = false):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            responseType: 'blob'
        };
        axiosFetch(clientGuid).getWithConfig<Blob>('api/Download/DownloadAllSignedDocumentAsync?'
            + "fileName=" + encodeURIComponent(fileName) + "&isSigningCompleted=" + isSigningCompleted + "&downloadStep=" + downloadStep + "&isMigratedSigningId=" + isMigratedSigningIdForAudit, options)
            .then(function (response: AxiosResponse<Blob>) {
                const { data } = response;
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`downloadAllDocument failed for the request having parameters ${JSON.stringify({
                    clientGuid: clientGuid,
                    fileName: fileName,
                    downloadStep: downloadStep
                })} with error ${error.message}`)
            });
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        }
}

const unloadedState: IDownloadData = {
    documents: []
} as IDownloadData;

export const reducer: Reducer<IDownloadData> = (state: IDownloadData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_DOCUMENTS:
            return {
                documents: action.documents,

            } as IDownloadData;
        case actionTypes.REQUEST_DOCUMENTS:
            return {
                documents: []
            } as IDownloadData;

        default:
            return state || unloadedState;
    }
};