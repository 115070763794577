import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inMemoryToken } from './Core/Services/DataAccess/DataService.Axios'
import { TYPES } from './Startup/types';
import { container } from './Startup/inversify.config';
import { ILocalStore } from './Core/Utilities/LocalStore';

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export const ProtectedRoute = ({ component: Component, layout: Layout, path, ...rest }: any) => {
    return (
        <Route
            path={path}
            {...rest}
            render={props => {
                if((props.match.url.includes('esign') || props.match.url.includes('download')) && !localStore.get('loggedIn')){
                    localStore.set('loggedIn', true);
                }
                return localStore.get('loggedIn') ? localStore.get(props.match.params.clientId) && props.match.url.includes('esign') ?
                    (
                        <Redirect
                            to={{
                                pathname: "/invalid",
                                state: {
                                    prevLocation: path,
                                    error: "This page is not accessible",
                                },
                            }}
                        />
                    ) : (
                        <Layout {...props}>
                            <Component {...props} />
                        </Layout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login/" + props.match.params.clientId,
                                state: {
                                    prevLocation: path,
                                    error: "You need to login first!",
                                },
                            }}
                        />
                    );
            }}
        />
    );
};