export interface ICompanyModel {
    CompanyId: number,
    CompanyName: string,
    IsTestCompany: boolean
}

export interface ICompanyLogo{
    isCompanyLogo: boolean, 
    companyLogoPath: string
}

export enum FirmType {
    None = 0,
    Live = 1,
    Internal = 2,
}