import { ControlData, RadioButton } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { IConditionalControlRule } from "./IConditionalControlRule";
export class DocumentViewModel {

	id: number;
	name: string;
	fileLink: string;
	disabled: boolean;
	documentType: DocumentType;
	documentControls: ISignaturePageData[];
    conditionalControlRules: IConditionalControlRule[];

    constructor(id: number, name: string, fileLink: string, disabled: boolean, documentType: DocumentType, documentControls: ISignaturePageData[], conditionalControlRules: IConditionalControlRule[]) {

		this.id = id;
		this.name = name;
		this.fileLink = fileLink;
		this.disabled = disabled;
		this.documentType = documentType;
		this.documentControls = documentControls;
        this.conditionalControlRules = conditionalControlRules;
    }

    public static create(id: number, name: string, fileLink: string, disabled: boolean, documentType: DocumentType, documentControls: ISignaturePageData[], conditionalControlRules: IConditionalControlRule[]) {
        return new DocumentViewModel(id, name, fileLink, disabled, documentType, documentControls, conditionalControlRules);
    }

    public static createNullObject() {
        return new DocumentViewModel(0, "", "", false, DocumentType.createNullObject(), [], []);
    }

}

export class DocumentType {

	id: number;
	name: string;

	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
    }


    public static createNullObject() {
        return new DocumentType(0, "");
    }
}


export interface ISignaturePageData {
	pageNo: number;
    signatureControls: ISignatureControlModel[]
}

export class SignaturePageData implements ISignaturePageData {
    pageNo: number;
    signatureControls: ISignatureControlModel[]


    constructor(pageNo: number, signatureControls: ISignatureControlModel[]) {
        this.pageNo = pageNo;
        this.signatureControls = signatureControls;
    }

    public static create(pageNo: number, signatureControls: ISignatureControlModel[]) {
        return new SignaturePageData(pageNo, signatureControls);
    }

}

export interface ISignatureControlModel {
    controlGuid: string;
	signer: string;
	type: SignatureControlType;
    role: SignatureControlRole;
	top: number;
    left: number;
    width: number;
    height: number;
	required: boolean;
	tooltip: string;
    controlData: IControlData;
    items: RadioButton[];
}

export enum SignatureControlRole {
	None = 0,
	SFSender = 1,
	SFReceipient = 2
}

export enum SignatureControlType {
	None = 0,
	Signature = 1,
	Initial = 2,
	Date = 3,
	NameAndTitle = 4,
	Company = 5,
    Text = 6,
    Name = 7,
    Title = 8,
    CheckBox = 9,
    PrintName = 10,
    Radio = 11
}

export class SignatureControlModel implements ISignatureControlModel {

    signer: string;
    type: SignatureControlType;
    top: number;
    left: number;
    width: number;
    height: number;
    role: SignatureControlRole;
    tooltip: string;
    required: boolean;
    controlData: any;
    items: RadioButton[];
    controlGuid: string;

    constructor(signer: string,
        type: SignatureControlType,
        top: number,
        left: number,
        width: number,
        height: number,
        role: SignatureControlRole,
        tooltip: string,
        required: boolean,
        controlData: any,
        items: RadioButton[],
        controlGuid: string) {
    

        this.signer = signer,
            this.type = type,
            this.top = top,
            this.left = left,
            this.width = width,
            this.height = height,
            this.role = role,
            this.tooltip = tooltip,
            this.required = required,
            this.controlData = controlData;
            this.items = items;
            this.controlGuid = controlGuid;

    }

    public static create(signer: string,
        type: SignatureControlType,
        top: number,
        left: number,
        width: number,
        height: number,
        role: SignatureControlRole,
        tooltip: string,
        required: boolean,
        controlData: any,
        items: RadioButton[],
        controlGuid: string) {

        return new SignatureControlModel(signer, type, top, left, width, height, role, tooltip, required, controlData, items,controlGuid);

    }

}

export interface IControlData {

}

export interface ITextData extends IControlData
{
	value:string
}

export class DocumentControlData {

	type: number;
	controlData?: ControlData;

	constructor(type: number, controlData?: ControlData) {
		this.type = type
		this.controlData = controlData;
	}

	public static create(type: number, controlData?: ControlData) {
		return new DocumentControlData(type, controlData);
	}

}