import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { PublicLogin } from './PublicLogin';
import * as PublicStore from '../../store/Common/PublicStore';
import * as LoaderStore from '../../store/Common/LoaderStore';
import * as AuthenticationStore from '../../store/Account/AuthenticationStore';
import { MetroSpinner } from 'react-spinners-kit';
import LoadingOverlay from 'react-loading-overlay';
import { AuthenticationLogin } from './Authentication/AuthenticationLogin';
import { AuthenticationType } from '../../Core/Domain/ViewModels/PrimaryDetails';

export type RouteSelectorProps = {
    publicUserToken: PublicStore.IPublicUserTokenData;
    publicData: PublicStore.IPublicData;
    userData: AuthenticationStore.ITokenData;
    loaderData: LoaderStore.ILoadingData;
} & typeof AuthenticationStore.actionCreators &
    typeof PublicStore.actionCreators &
    RouteComponentProps<{}>;

export class RouteSelector extends React.Component<RouteSelectorProps, {}> {
    public render() {
        return !this.props.publicData.isLoading &&
            this.props.publicData.primaryDetails.authenticationInfo != undefined ? (
            this.props.publicData.primaryDetails &&
            this.props.publicData.primaryDetails.authenticationInfo.authenticationType === AuthenticationType.None ? (
                <PublicLogin
                    getAllStates={this.props.getAllStates}
                    publicUserToken={this.props.publicUserToken}
                    getTokenForAnonymous={this.props.getTokenForAnonymous}
                    publicData={this.props.publicData}
                    loaderData={this.props.loaderData}
                    requestPrimaryDetails={this.props.requestPrimaryDetails}
                    requestCompanyLogo={this.props.requestCompanyLogo}
                    requestClientSignatureStatus={this.props.requestClientSignatureStatus}
                    dispatchUserToken={this.props.dispatchUserToken}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                    updateLockState={this.props.updateLockState}
                />
            ) : (
                <AuthenticationLogin
                    getAllStates={this.props.getAllStates}
                    publicData={this.props.publicData}
                    userData={this.props.userData}
                    loaderData={this.props.loaderData}
                    requestPrimaryDetails={this.props.requestPrimaryDetails}
                    requestAccessCode={this.props.requestAccessCode}
                    validateParam={this.props.validateParam}
                    refreshToken={this.props.refreshToken}
                    getTokenForAnonymous={this.props.getTokenForAnonymous}
                    requestClientSignatureStatus={this.props.requestClientSignatureStatus}
                    history={this.props.history}
                    requestCompanyLogo={this.props.requestCompanyLogo}
                    location={this.props.location}
                    dispatchUserToken={this.props.dispatchUserToken}
                    match={this.props.match}
                    updateLockState={this.props.updateLockState}
                />
            )
        ) : (
            <LoadingOverlay
                active={this.props.loaderData.loading}
                spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.loaderData.loading} />}
            >
                <div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-page"></div>
                </div>
            </LoadingOverlay>
        );
    }
}
