import * as React from 'react';
import { IPrimaryDetails } from '../../../Core/Domain/ViewModels/PrimaryDetails';
import { Button } from 'react-bootstrap';

interface Props {
    primaryDetails: IPrimaryDetails
    OnMobileProceedClick: () => void;
}

export class MobileViewNotification extends React.Component<Props, {}, {}>  {
    public render() {
        return (
            <div className="notification-page">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 page-container">
                    <div>
                        <div style={{ textAlign: "center" }}>
                            <span>
                                Welcome {this.props.primaryDetails.loggedInClient},
                                <br />
                                <strong>{this.props.primaryDetails.contactPerson?.firstName} {this.props.primaryDetails.contactPerson?.lastName}</strong>
                                <br/>
                                For the best experience, we recommend using a desktop or laptop computer.
                            </span>
                        </div>
                        <br />
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <i className="fa fa-laptop" style={{ fontSize: 64, color: "yellowgreen" }}></i>
                        </div>
                        <br />
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <span>
                                Accessing SafeSend Signatures on a mobile device (tablet or phone), may require scrolling and resizing 
                                that is not intuitive or optimal for viewing or e-signing.
                            </span>
                            <br />
                            <br />
                        <Button onClick={this.props.OnMobileProceedClick} className="btn btn-proceed-mobile">Proceed on Mobile Device</Button>
                        </div>
                        </div>
                </div>
            </div>
        )
    }
}