import * as React from 'react';
import { Logout } from '../Account/Logout/Logout';
import { match } from 'react-router';
import { History } from 'history';
import { IAuthenticationInfo, IClientUTEInfo, IUserModel } from 'src/Core/Domain/ViewModels/PrimaryDetails';
import { UserIcon } from '../Svg/CustomSvgIcons';
import { PopoverTriggerComponent } from 'cp-common-ui-components';
import { HomeIcon } from '../Svg/CustomSvgIcons';

export interface HeaderProps {
    title: string;
    match?: match;
    history?: History;
    loggedIn: boolean;
    isMobileView?: boolean;
    companyLogoPath?: string | null;
    contactPerson?: IUserModel;
    clientUTEInfo: IClientUTEInfo;
}

interface HeaderState {
    showHomeIcon: boolean;
}

export class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
    }

    redirectToOneHubAuth = () => {
        window.location.href = this.props.clientUTEInfo?.uteRedirectURL;
    };
    public render() {
        let contactInfoPopover = (
            <div id="contact-info-popover">
                <div className="contact-info-header">Contact Information</div>
                <div className="contact-info-body">
                    <div id="divPopUpName" className="medium">
                        {this.props.contactPerson?.firstName} {this.props.contactPerson?.lastName}
                    </div>
                    {this.props.contactPerson && this.props.contactPerson?.phoneNumber && (
                        <div>
                            <label>{this.props.contactPerson?.phoneNumber}</label>
                        </div>
                    )}
                    <div className="mail">
                        <a className="sapphire-color" href={'mailto:' + this.props.contactPerson?.email}>
                            {this.props.contactPerson?.email}
                        </a>
                    </div>
                    {  this.props.clientUTEInfo?.isExistingUTEClient &&
                        !this.props.clientUTEInfo?.isAccessedFromUTE && (
                            <div className="access-onehub" 
                            onClick={() => this.redirectToOneHubAuth()}>
                                Access Client Portal
                            </div>
                         )
                        } 
                </div>
            </div>
        );
        const showHomeIcon =
            this.props.clientUTEInfo.isAccessedFromUTE &&
            this.props.clientUTEInfo.isExistingUTEClient &&
            this.props.clientUTEInfo.uteRedirectURL &&
            !this.props.isMobileView;
        return (
            <header className="app-header" style={this.props.isMobileView ? { textAlign: 'center' } : {}}>
                {this.props.companyLogoPath && !this.props.isMobileView ? (
                    <div className="company-logo">
                        <img src={this.props.companyLogoPath} alt="Company Logo" />
                    </div>
                ) : (
                    this.props.title && !this.props.isMobileView && <p className="company-name">{this.props.title}</p>
                )}
                {showHomeIcon && (
                    <div className="home_icon_container">
                        <button className="home_icon_button" onClick={() => this.redirectToOneHubAuth()}>
                            <HomeIcon fill="#FFF" /> Home
                        </button>
                    </div>
                )}
                {this.props.title &&
                this.props.contactPerson !== null &&
                this.props.contactPerson !== undefined &&
                !this.props.isMobileView ? (
                    <PopoverTriggerComponent
                        popoverContent={contactInfoPopover}
                        popoverId={'contactInfoPopover'}
                        popoverContentClass="contact-info-content"
                    >
                        <div className="contact-person-header">
                            <a className="company-name">
                                <UserIcon /> Contact Person
                            </a>
                        </div>
                    </PopoverTriggerComponent>
                ) : null}
                {this.props.title && this.props.loggedIn && !this.props.isMobileView ? (
                    <Logout history={this.props.history} match={this.props.match} />
                ) : null}
            </header>
        );
    }
}
